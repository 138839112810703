import React from 'react';
import ARTICLE_SLIDER from 'mocks/stadtcasino-baden-slider';

const ArticleSlider = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: ARTICLE_SLIDER,
      }}
    ></div>
  );
};

export default ArticleSlider;
